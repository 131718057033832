<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>
            <div>
              <div class="row pb-4 mr-0 df">
                <h2 class="m-0 col">{{ $t('fileStatuses') }}</h2>
                <div>
                  <input
                    v-model="search"
                    style="width: 200px"
                    type="text"
                    class="form-control d-inline-block mr-2"
                    :placeholder="$t('filterTable')"
                  />
                </div>
              </div>
              <div class="tabe-responsive">
                <b-overlay :show="!ready" rounded="sm">
                  <table
                    ref="table"
                    class="table-style table-settings table_sort"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{{ $t('type') }}</th>
                        <th>{{ $t('status') }}</th>
                        <th>
                          {{ $t('logs') }}
                          <span
                            v-b-tooltip.hover
                            class="tip-icon"
                            :title="$t('fileLogsHint')"
                          >
                            <font-awesome-icon
                              icon="question-circle"
                            />
                          </span>
                        </th>
                        <th>
                          {{ $t('action') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(file, index) in filteredJobs">
                        <tr :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>
                            {{ file.type }}
                          </td>
                          <td
                            :class="{
                              'green-col': file.hint === 'C',
                              'grey-col': file.hint === 'I',
                              'purple-col': file.hint === 'P',
                              'red-col': file.hint === 'F'
                            }"
                          >
                            {{ file.status.toLowerCase() }}
                          </td>
                          <td
                            :class="file.logs ? 'cursor-shape' : ''"
                            style="color:blue"
                            @click="showLogsModal(file.logs)"
                          >
                            {{ file.logs ? 'Click me' : '---' }}
                          </td>
                          <td>
                            <span
                              class="link-remove"
                              @click="showRemoveJobModal(file.id)"
                            >
                              <font-awesome-icon icon="times" />
                              {{ $t('remove') }}
                            </span>
                          </td>
                        </tr>
                      </template>
                      <div v-if="!jobs || !jobs.length">
                        <tr>
                          <td>
                            <strong>{{
                              $t('noRecordsFound')
                            }}</strong>
                          </td>
                        </tr>
                      </div>
                    </tbody>
                  </table>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <logs-modal :logs="logs" />
    <b-modal
      id="modal-remove"
      :on-enter-key-press="removeJobRecord"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button
          variant="danger"
          style="margin:2px;"
          class="mt-2"
          @click="$bvModal.hide('modal-remove')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          style="margin:2px;"
          class="mt-2"
          primary
          @click="removeJobRecord"
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import OkkuApi from '@/services/OkkuApi'
import { mapState } from 'vuex'
import LogsModal from '@/components/modals/LogsModal'

export default {
  name: 'UploadedFileStatuses',
  components: {
    LogsModal
  },
  data() {
    return {
      jobs: [],
      filteredJobs: [],
      ready: true,
      search: '',
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      logs: [],
      jobId: ''
    }
  },
  computed: {
    ...mapState('common', {
      userInfo: '$userInfo'
    })
  },
  watch: {
    search() {
      if (this.search) {
        const searchKey = this.search.toString().toLowerCase()
        const filteredJobs = []
        const jobs = [...this.jobs]

        jobs.forEach(job => {
          const { type, status } = job
          if (
            type
              .toString()
              .toLowerCase()
              .includes(searchKey) ||
            status
              .toString()
              .toLowerCase()
              .includes(searchKey)
          ) {
            filteredJobs.push(job)
          }
          // Set filtered records
          this.filteredJobs = filteredJobs
        })
      } else {
        this.filteredJobs = [...this.jobs]
      }
    }
  },
  mounted() {
    this.getFileStatus()
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },
    async removeJobRecord() {
      try {
        await OkkuApi.removeJobRecord(
          this.userInfo.userId,
          this.jobId
        )
        this.updateAlert({
          variant: 'success',
          text: this.$t('changesSaved')
        })
      } catch (error) {
        this.updateAlert({
          variant: 'danger',
          text: this.$t('pleaseTryAgain')
        })
      } finally {
        this.getFileStatus()
        this.$bvModal.hide('modal-remove')
      }
    },
    showRemoveJobModal(jobId) {
      this.jobId = jobId
      this.$bvModal.show('modal-remove')
    },
    async getFileStatus() {
      try {
        const jobs = await OkkuApi.getFileStatusList(
          this.userInfo.userId
        )
        this.jobs = jobs
        this.filteredJobs = jobs
      } catch (error) {
        this.updateAlert({
          variant: 'success',
          text: this.$t('pleaseTryAgain')
        })
        this.jobs = []
      }
    },
    async showLogsModal(logs) {
      if (logs) {
        this.logs = JSON.parse(logs)
        this.$bvModal.show('logs-modal')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-settings.table-settings {
  th,
  td {
    text-align: left !important;
  }
}
.cursor-shape {
  cursor: pointer;
}
.red-col {
  color: red;
}
.green-col {
  color: green;
}
.grey-colr {
  color: grey;
}
.purple-col {
  color: purple;
}
</style>
