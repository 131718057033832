<template>
  <b-modal
    id="logs-modal"
    hide-footer
    size="md"
    scrollable
    :title="$t('logs')"
    no-close-on-backdrop
    no-close-on-esc
  >
    <p style="color:red">
      Following records were not inserted, please compare row number
      in CSV file
    </p>
    <div class="row">
      <div class="col-6 text-left">
        <template v-for="(log, index) in logs">
          <p v-if="log.id % 2 !== 0" :key="index">
            Row : {{ log.id }}
          </p>
        </template>
      </div>
      <div class="col-6 text-left">
        <template v-for="(log, index) in logs">
          <p v-if="log.id % 2 === 0" :key="index">
            Row : {{ log.id }}
          </p>
        </template>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    logs: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>
